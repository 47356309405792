<template>
  <div class="component-tab-bar">
    <ul class="tab-bar row container">
      <li class="col">
        <a
          href="#"
          :class="{ active: selected === 'search' }"
          @click.prevent="routeTo('/events')"
        >
          <i class="fal fa-search" />
          <span>Activiteiten</span>
        </a>
      </li>
      <li v-if="groupsEnabled" class="col">
        <a
          href="#"
          :class="{ active: selected === 'groups' }"
          @click.prevent="routeTo('/groups')"
        >
          <i class="fal fa-users" />
          <span>Groepen</span>
        </a>
      </li>
      <li class="col">
        <a
          href="#"
          :class="{ active: selected === 'organize' }"
          @click.prevent="startOrganize"
        >
          <i class="fal fa-plus" />
          <span>Nieuw</span>
        </a>
      </li>
      <li class="col">
        <a
          href="#"
          :class="{ active: selected === 'events' }"
          @click.prevent="routeTo('/event-list-own')"
        >
          <i class="fal fa-calendar-alt" />
          <span>Mijn events</span>
        </a>
      </li>
      <li class="col">
        <a
          href="#"
          :class="{ active: selected === 'locations' }"
          @click.prevent="routeTo('/locations')"
        >
          <i class="fal fa-map-marker-alt" />
          <span>Locatie huren</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import config from '@/utils/config'
import { EVENT_CREATE_RESET } from '@/store/actions/event'

export default {
  name: 'TabBar',
  props: {
    selected: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      groupsEnabled: config.featureFlags.groups,
      creditcardsEnabled: config.featureFlags.creditcards
    }
  },
  methods: {
    routeTo(path) {
      this.$router.push(path)
    },
    startOrganize: async function () {
      this.$store.commit(EVENT_CREATE_RESET)
      if (this.creditcardsEnabled) {
        this.routeTo('/organize')
      } else {
        this.routeTo('/events/new')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables';
.component-tab-bar {
  background: $ipWhite;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4rem;
  padding: 0;
  overflow: hidden;
  border-top: 1px solid $greyLight;
}
.component-tab-bar .tab-bar {
  list-style: none;
  margin: 0 auto;
  padding: 0;
}
.component-tab-bar .tab-bar li {
  padding: 0;
}
.component-tab-bar .tab-bar li a {
  color: $ipBlack;
  text-align: center;
  display: block;
  text-decoration: none;
  padding-top: 5px;
}
.component-tab-bar .tab-bar li a.active {
  color: $ipGreen;
}
.component-tab-bar .tab-bar li a .icon-svg {
  width: 22px;
  height: 22px;
}
.component-tab-bar .tab-bar li a i {
  width: 22px;
  height: 22px;
}
.component-tab-bar .tab-bar li a span {
  display: block;
  font-size: 0.625em;
  font-weight: 500;
}
</style>
